import { LeaveApprovers } from "./approvers.model";

export class LeaveRequest {
    id: string;
    userId:string;
    userName: string;
    userIdFiscal: string;
    requestDate: Date;
    expirationDate: Date;
    startDate: Date;
    endDate: Date;
    leaveTypeName: string;
    leaveTypeId: number;
    stateName: string;
    stateId: string;
    note: string;
    daysConsumed: number;
    selected:boolean;
    organizationalUnitId: number;
    actionApprovers: LeaveApprovers[];
    useWorkflowApprove: boolean;
    documentId:number;
}
